import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/wettelijke-verklaring/">NL</a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/mentions-legales/">FR</a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="984383bd334d1c944952660bbbcaa6a4ce531c62"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="active first overview last">
                <a href="/fr/mentions-legales/"> Mentions légales</a>
              </li>
            </ul>
          </div>
          <div className="page nine columns page-38">
            <h1>Mentions légales</h1>
            <p>
              Le contenu de ce site Internet est la propriété de et est contrôlé
              par Johnson &amp; Johnson Consumer S.A., et toutes les
              informations qui y sont contenues sont protégées par les lois
              relatives aux droits d'auteur en vigueur à travers le monde. Il
              est permis de télécharger des informations pour votre usage
              personnel et à des fins non commerciales. Toutefois, toute
              modification ou reproduction du contenu de se site est strictement
              interdite. Par ailleurs, les informations de ce site ne peuvent
              être ni copiées ni utilisées sous quelle forme que ce soit.
            </p>
            <p>
              <br />
              Johnson &amp; Johnson Consumer S.A. mettra tout en oeuvre pour
              mettre à jour les informations de ce site. Johnson &amp; Johnson
              Consumer S.A. ne peut être tenu pour responsable des éventuels
              dommages ou lésions faisant suite à votre accès, ou à votre
              impossibilité d'accès, à ce site Internet, ou résultant de votre
              confiance en des informations fournies sur ce site. Ce site est
              destiné au public de la Belgique et du Luxembourg.
            </p>
            <p>
              <br />
              Ce site Internet peut proposer des liens et des références vers
              d'autres sites mais Johnson &amp; Johnson Consumer S.A. n'est en
              rien responsable du contenu de ces autres sites, ni d'éventuels
              dommages ou lésions encourus des suites de leur contenu. Tous les
              liens vers d'autres sites sont proposés par pur souci de facilité
              pour les utilisateurs de ce site Internet.
            </p>
            <p>
              <br />
              Les marques commerciales, marques de services, marques déposées et
              produits apparaissant sur ce site Internet sont protégés à
              l'échelle internationale. Il est strictement interdit de les
              utiliser sans l'accord écrit préalable de Johnson &amp; Johnson
              Consumer S.A., sauf pour identifier les produits ou services de la
              société.
            </p>
            <p>
              <br />
              Toutes les communications adressées à ce site Internet sont régies
              par le{" "}
              {" "}
              d'application à ce site. Johnson &amp; Johnson Consumer S.A. a le
              droit d'utiliser le contenu de ces communications, à l'inclusion
              des idées, inventions, concepts, techniques ou savoir-faire y
              paraissant, et ce, à toute fin définie à l'introduction de ces
              communications sur ce site. Le contenu des communications peut
              notamment servir au développement, à la fabrication et/ou à la
              commercialisation de biens ou services. Les expéditeurs de
              communications adressées à ce site Internet ou à Johnson &amp;
              Johnson Consumer S.A. sont responsables du contenu et des
              informations qui y figurent, de même que de leur véracité et de
              leur exactitude.
            </p>
            <p>
              <br />
              Ce site Internet fait office de service à l'attention de ses
              visiteurs. Johnson &amp; Johnson Consumer S.A. se réserve le droit
              d'effacer, de modifier ou de compléter le contenu de ce site, à
              tout moment, pour toute raison.
            </p>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
